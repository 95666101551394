import type { ComponentType, ReactElement } from 'react'
import type { Control, FieldPath, FieldValues } from 'react-hook-form'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'

interface FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  control: Control<TFieldValues, any>
  label?: string
  renderAfterLabel?: () => ReactElement
  helper?: string
  name: TName
}

export function withRHF<TProps>(Component: ComponentType<TProps>) {
  function Render<TFieldValues extends FieldValues>(
    props: TProps & FormFieldProps<TFieldValues>
  ) {
    const {
      control,
      name,
      label,
      helper,
      renderAfterLabel,
      ...componentProps
    } = props

    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FormItem>
              {label && (
                <span className="flex items-end justify-between">
                  <FormLabel htmlFor={`${name}-f`}>{label}</FormLabel>
                  {renderAfterLabel && renderAfterLabel()}
                </span>
              )}
              <FormControl>
                <Component {...(componentProps as any)} {...field} />
              </FormControl>
              {helper && <FormDescription>{helper}</FormDescription>}
              <FormMessage />
            </FormItem>
          )
        }}
      />
    )
  }

  Render.displayName = `withRHF(${Component.displayName || 'Field'})`

  return Render
}
