import React from 'react'
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi'

import type { MustHaveProps } from '@/hocs/with-form'
import { c } from '@/utils/etc'

import FieldInput from '../field-input'
import { usePasswordInput } from './password-input.hook'

export interface PasswordInputProps extends MustHaveProps {
  name: string
  label?: string
  labelTooltip?: string
  error?: boolean
  onChange?: (v: unknown) => void
  onBlur?: () => void
  onFocus?: () => void
}

const eye = {
  true: <HiOutlineEye className="h-6 w-6" />,
  false: <HiOutlineEyeOff className="h-6 w-6" />,
}

const PasswordInput: React.FCC<PasswordInputProps> = ({
  name,
  label,
  labelTooltip,
  className,
  inputWrapperClassName,
  value,
  passthrough,
  ...props
}) => {
  const { hidden, toggleHidden } = usePasswordInput()

  return (
    <FieldInput
      name={name}
      label={label}
      labelTooltip={labelTooltip}
      type={hidden ? 'password' : 'text'}
      className={c('pr-12', className)}
      inputWrapperClassName={c('relative', inputWrapperClassName)}
      value={value as never}
      passthrough={{
        placeholder: '••••••••••',
        ...passthrough,
      }}
      {...props}
    >
      <span slot="after" className={c('absolute inset-y-0 right-0')}>
        <button
          tabIndex={-1}
          disabled={passthrough?.disabled}
          className={c(
            'h-full px-3 text-light-gray-400',
            passthrough?.disabled && 'text-light-gray-300'
          )}
          onClick={toggleHidden}
          type="button"
        >
          {eye[hidden ? 'true' : 'false']}
        </button>
      </span>
    </FieldInput>
  )
}

export default PasswordInput
