import * as React from 'react'
import { IMaskInput } from 'react-imask'

import type { MustHaveProps } from '@/hocs/with-form'
import { env } from '@/utils/envs'
import { c } from '@/utils/etc'

import TextInfo from '../../TextInfo'
import type { InputTypes } from './field-input.hook'
import { useFieldInput } from './field-input.hook'

export interface SharedFieldInputProps {
  slot?: 'before' | 'after'
  locale?: string
  className?: string
  containerClassName?: string
  inputWrapperClassName?: string
  labelClassName?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  passthrough?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
}

export type FieldInputProps = MustHaveProps & {
  name: string
  disabled?: boolean
  value?: string
  type?: InputTypes
  label?: string
  labelTooltip?: string
  footer?: string
  error?: boolean
  placeholder?: string
  onChange?: (v: any) => void
  onBlur?: () => void
  onFocus?: () => void
  renderAfterLabel?: React.ReactNode
  children?: React.ReactNode
  maskOptions?: any
}

const FieldInput = React.forwardRef<HTMLInputElement, FieldInputProps>(
  (
    {
      type = 'text',
      name,
      value,
      defaultValue,
      label,
      labelTooltip,
      children,
      className,
      error,
      containerClassName,
      inputWrapperClassName,
      passthrough,
      disabled,
      placeholder,
      maskOptions,
      onChange,
      onBlur,
      onFocus,
      renderAfterLabel,
      locale = env.LANGUAGE,
      footer,
    },
    ref
  ) => {
    const { masks, id, before, after, realtypes, defaultPlaceholders } =
      useFieldInput({
        name,
        locale,
        children,
      })

    return (
      <div
        className={c('group', error && 'form-field-error', containerClassName)}
      >
        {label && (
          <>
            {labelTooltip ? (
              <TextInfo
                as="label"
                info={labelTooltip}
                className="form-label"
                htmlFor={id}
              >
                {label}
                {renderAfterLabel}
              </TextInfo>
            ) : (
              <label htmlFor={id} className="form-label flex items-center">
                {label}
                {renderAfterLabel}
              </label>
            )}
          </>
        )}

        <div className={c(inputWrapperClassName)}>
          {before}

          <IMaskInput
            placeholder={placeholder || defaultPlaceholders[type]}
            disabled={disabled}
            id={id}
            name={name}
            className={c('form-field', className)}
            onAccept={onChange}
            onBlur={onBlur as never}
            onFocus={onFocus as never}
            type={realtypes[type]}
            inputRef={ref}
            defaultValue={defaultValue}
            value={value}
            {...(passthrough as any)}
            {...masks[type]}
            {...maskOptions}
          />

          {after}
        </div>

        {!error && footer ? (
          <p className="text-caption-md mt-2">{footer}</p>
        ) : null}
      </div>
    )
  }
)

export default FieldInput
