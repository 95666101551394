import { createNavigation } from 'next-intl/navigation'
import { defineRouting } from 'next-intl/routing'
import type { ComponentPropsWithoutRef } from 'react'

import { config } from './i18n'

const { locales, defaultLocale } = config

export const routing = defineRouting({
  locales,
  defaultLocale,
  localePrefix: 'never',
  pathnames: config.pathnames,
})

const {
  Link,
  useRouter,
  usePathname,
  redirect,
  getPathname: _getPathname,
} = createNavigation(routing)

export type Href = ComponentPropsWithoutRef<typeof Link>['href']

export const getPathname = (href: Href) =>
  _getPathname({ href: href as never, locale: config.defaultLocale })

export const isLinkActive = (href: Href, pathname: string) => {
  return pathname.split('?')[0]?.startsWith(getPathname(href))
}

export { Link, redirect, usePathname, useRouter }
