import {
  arrow,
  autoPlacement,
  FloatingArrow,
  FloatingPortal,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import React from 'react'
import { TbInfoCircleFilled } from 'react-icons/tb'

type TextInfoProps<T> = T & {
  as?: React.ElementType<T>
  info: string
}

type PProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>

const TextInfo = <T extends { children?: React.ReactNode } = PProps>(
  props: TextInfoProps<T>
) => {
  const { as = 'p', info, children, ...elementProps } = props

  const [isOpen, setIsOpen] = React.useState(false)

  const arrowRef = React.useRef(null)
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [autoPlacement(), arrow({ element: arrowRef })],
  })
  const hover = useHover(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  return (
    <>
      {React.createElement(
        as,
        elementProps as unknown as T,
        <>
          {children}
          {info && (
            <>
              {' '}
              <div
                ref={refs.setReference}
                className="inline group"
                {...getReferenceProps()}
              >
                <TbInfoCircleFilled
                  size={20}
                  className="inline text-dark-gray-100"
                />
              </div>
            </>
          )}
        </>
      )}

      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="z-50 bg-dark-gray-400 text-white [text-align:balance] max-w-[250px] text-caption-md font-medium p-2 rounded-md"
            {...getFloatingProps()}
          >
            {info}
            <FloatingArrow
              fill="currentColor"
              className="text-dark-gray-400"
              ref={arrowRef}
              context={context}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export default TextInfo
